
import type { ElTable } from 'element-plus'
import { defineComponent, computed,onMounted,ref} from "vue";
import {useEntGeos} from "@/composables/useEntGeos";
import {useBande} from "@/composables/useBande";
import _ from 'lodash';

export default defineComponent({
  name: "blr-liste-recap",
  props: {
     data : {type:Array,default:[]},
  },
  components: {},
  setup(props, context) {
    

    const {getVilleDescription} = useEntGeos();
    const {getBandeDescription, getBandes} = useBande("blr");

    onMounted(() => {
      getBandes()
    })

    return {
      getVilleDescription,
      getBandeDescription,
    };
  }
});
