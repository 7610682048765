
import { defineComponent, computed, ref} from "vue";
import BlrRecap from "@/components/networks/blr/recap/auth/BlrRecap.vue";

import { Field, ErrorMessage,useField} from "vee-validate";
import { useEntGeos } from "@/composables/useEntGeos";

export default defineComponent({
  name: "step-3",
  props: {
     data : {type:Object, default:null},
     currentStep : {type:Boolean, default:false}
  },
  setup(props){
    

    const {getVilleDescription} = useEntGeos();
    const confirmed = ref(false)

    const isBlr= computed(() => {return props.data.typeReseau.toUpperCase() == 'BLR' ?  true : false})
    
    return{
      isBlr,
      confirmed,
      getVilleDescription
      
    }
  },
  components: {Field, ErrorMessage, BlrRecap},
});
