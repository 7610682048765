
import { defineComponent, computed } from "vue";
import BlrForm from "@/components/networks/blr/form/BlrForm.vue";

export default defineComponent({
  name: "step-1",
  setup(props){

    // Affichage du nom du tableau
    const showStepLabel = computed(() => 
    {
      let subtitle = "Veuillez préciser les détails du réseau"
      let title = "Réseau BLR",
          icon = "las la-wifi fs-1";
      return {title, subtitle, icon}
    })
        
    return{
      showStepLabel,
    }
  },
  components: {
    BlrForm
  },
});
