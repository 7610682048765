import { v4 as uuidv4 } from 'uuid';


 export const setData =(data: any = undefined) => {
    return     {
          id: data != undefined ? data.id : uuidv4(),
          bande :
          {
            debut: data != undefined ? data.bande.debut : undefined,
            fin: data != undefined ? data.bande.fin : undefined,
            libelle: data != undefined ? data.bande.libelle :"" ,
            id: data != undefined ?  data.bande.id : "",
          },
          bandeAutreFin: data != undefined ? data.bandeAutreFin : undefined,
          bandeAutreDebut: data != undefined ? data.bandeAutreDebut : undefined,
          bandeAutre: data != undefined ? data.bandeAutre : undefined,
          largeurBande:data != undefined ? data.largeurBande : undefined,
          frequencePorteuse:data != undefined ? data.frequencePorteuse : undefined,
          sites: [
            {
              code: data != undefined ?  data.sites[0].code : "A" ,
              nom: data != undefined ?  data.sites[0].nom  : "",
              longitude: data != undefined ?  data.sites[0].longitude  : "",
              latitude: data != undefined ?  data.sites[0].latitude  : "",
              entGeo: {
                id: data != undefined ?  data.sites[0].entGeo.id  : ""
              
                 } 
              },
              {
                code: data != undefined ?  data.sites[1].code  : "B",
                nom: data != undefined ?  data.sites[1].nom  : "",
                longitude: data != undefined ?  data.sites[1].longitude  : "",
                latitude: data != undefined ?  data.sites[1].latitude  : "",
                entGeo: {
                  id: data != undefined ?  data.sites[1].entGeo.id  : ""
                }
              }
          ]
        }
  }